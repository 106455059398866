.pageTitle {
    flex: -1;
    width: 100vw;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    h1 {
        margin-left: 30px;
        color: #03478E;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        font-size: 1.7em;
    }
}