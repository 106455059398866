body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  transition: all 300ms 0ms;
  color: black;
  text-decoration: none;
  font-weight: 600 !important;
  opacity: 1;
}

a:hover {
  transition: all 300ms 0ms;
  opacity: 0.7;
}

h1, h2, h3, h4, h5, h6, p, a, :root {
  font-family: "Open Sans";
  font-weight: 300;
}