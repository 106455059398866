.careerFairEmployers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
        margin: 0px 48px 48px 18px;
        width: 60vw;
    }
    @media only screen and (max-width: 680px) {
        >*{
        width: 100vw;
        }
    }
}