.footer {
    background-color: #121212;
    color: white;
    display: flex;
    div {
        display: flex;
    }
    flex-direction: column;
    padding: 0.3in;
    >.linksAndContactInfo {
        flex-direction: row;
        >div {
            flex: 1;
            >div {
                flex-direction: column;
            }
        }
        h3{
            color: white !important;
        }
        a {
            font-weight: 300 !important;
            color: #999999;
        }
        p {
            padding: 0px;
            color: #999999;
            margin: 0px;
            font-weight: 300 !important;
            font-family: "Open Sans";
        }
        >.socialMediaAccounts {
            justify-content: center;
            text-align: center;
        }
        >.contactInfo {
            justify-content: right;
            text-align: right;
        }
    }
    >.copyrightNotice {
        margin-top: 20px;
        text-align: center;
        color: #777777;
    }
}