.E404S {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    text-align: center;
    >div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}