.aboutUsContact {
    display: flex;
    margin-right: 48px;
    >img {
        width: 60%;
        float: right;
    }
    >div {
        float: left;
        clear: right;
        width: 35%;
        >* {
            margin: 0px 24px 24px 18px;
        }
    }
}