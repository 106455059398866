body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  -webkit-transition: all 300ms 0ms;
  transition: all 300ms 0ms;
  color: black;
  text-decoration: none;
  font-weight: 600 !important;
  opacity: 1;
}

a:hover {
  -webkit-transition: all 300ms 0ms;
  transition: all 300ms 0ms;
  opacity: 0.7;
}

h1, h2, h3, h4, h5, h6, p, a, :root {
  font-family: "Open Sans";
  font-weight: 300;
}
.homeContent {
  margin: 0px;
  display: flex;
  flex-direction: column; }
  .homeContent .bigNewsSlider {
    height: 60vh; }
    .homeContent .bigNewsSlider .slider {
      position: relative !important;
      height: 100% !important; }
      .homeContent .bigNewsSlider .slider .sliderSlide {
        text-align: center; }
        .homeContent .bigNewsSlider .slider .sliderSlide > div {
          text-align: center;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-100%);
                  transform: translateY(-100%); }
  .homeContent .whoWeAre {
    background-color: #03478E;
    width: 100vw;
    text-align: center;
    color: white !important;
    padding: 5vh 0px 5vh 0px; }
    .homeContent .whoWeAre > h1 {
      color: white !important; }
    .homeContent .whoWeAre > p {
      margin-top: 2.5vw;
      position: relative;
      width: 60%;
      left: 50vw;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }

.aucompo {
  display: flex;
  flex-direction: column; }
  .aucompo .title {
    font-family: "Montserrat", sans-serif;
    line-height: 32px;
    font-size: 20px;
    color: #03478e; }
  .aucompo > * {
    padding: 5px 0px 5px 30px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em; }
  .aucompo .content {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #000000; }
    .aucompo .content > h2 {
      margin: 0px;
      font-weight: bold;
      line-height: 27px;
      font-size: 14px; }
    .aucompo .content > p {
      display: block;
      margin-top: 0px;
      margin-bottom: 17px; }
    .aucompo .content > p:last-child {
      margin-bottom: 0px; }

.header {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  margin: 30px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .header * {
    border-bottom: none !important;
    color: black !important;
    font-family: "Montserrat" !important;
    font-weight: 400 !important; }
  .header .horizontalNavs {
    display: flex;
    flex-direction: row; }
    .header .horizontalNavs > * {
      padding: 0px 20px 0px 20px;
      margin: 20px 0px 20px 0px; }

.footer {
  background-color: #121212;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 0.3in; }
  .footer div {
    display: flex; }
  .footer > .linksAndContactInfo {
    flex-direction: row; }
    .footer > .linksAndContactInfo > div {
      flex: 1 1; }
      .footer > .linksAndContactInfo > div > div {
        flex-direction: column; }
    .footer > .linksAndContactInfo h3 {
      color: white !important; }
    .footer > .linksAndContactInfo a {
      font-weight: 300 !important;
      color: #999999; }
    .footer > .linksAndContactInfo p {
      padding: 0px;
      color: #999999;
      margin: 0px;
      font-weight: 300 !important;
      font-family: "Open Sans"; }
    .footer > .linksAndContactInfo > .socialMediaAccounts {
      justify-content: center;
      text-align: center; }
    .footer > .linksAndContactInfo > .contactInfo {
      justify-content: right;
      text-align: right; }
  .footer > .copyrightNotice {
    margin-top: 20px;
    text-align: center;
    color: #777777; }

.pageTitle {
  flex: -1 1;
  width: 100vw;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
  .pageTitle h1 {
    margin-left: 30px;
    color: #03478E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 1.7em; }

.informativeTextBox {
  background-color: #F8F8F8;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0.5in;
  padding: 0.5in 0.5in 0.5in 0.5in; }

.drawerButton {
  background-color: transparent;
  border: none; }
  .drawerButton:focus {
    outline: none; }
  .drawerButton img {
    cursor: pointer;
    -webkit-transition: all 200ms 0s;
    transition: all 200ms 0s; }
  .drawerButton img:hover {
    -webkit-transition: all 200ms 0s;
    transition: all 200ms 0s;
    opacity: 0.5; }
  .drawerButton img:active {
    opacity: 0.3; }

.aboutUsScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .aboutUsScreen > :first-child {
    margin: 0px 0px 77px 0px !important;
    width: 100vw !important; }
  .aboutUsScreen > * {
    margin: 0px 48px 48px 18px;
    width: 60vw; }
  @media only screen and (max-width: 680px) {
    .aboutUsScreen > * {
      width: 100vw; } }

.aboutUsContact {
  display: flex;
  margin-right: 48px; }
  .aboutUsContact > img {
    width: 60%;
    float: right; }
  .aboutUsContact > div {
    float: left;
    clear: right;
    width: 35%; }
    .aboutUsContact > div > * {
      margin: 0px 24px 24px 18px; }

.careerFairScreen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .careerFairScreen > * {
    margin: 0px 48px 48px 18px;
    width: 60vw; }
  @media only screen and (max-width: 680px) {
    .careerFairScreen > * {
      width: 100vw; } }

.careerFairEmployers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .careerFairEmployers > * {
    margin: 0px 48px 48px 18px;
    width: 60vw; }
  @media only screen and (max-width: 680px) {
    .careerFairEmployers > * {
      width: 100vw; } }

.careerFairStudents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .careerFairStudents > * {
    margin: 0px 48px 48px 18px;
    width: 60vw; }
  @media only screen and (max-width: 680px) {
    .careerFairStudents > * {
      width: 100vw; } }

.careerFairScreen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .careerFairScreen > * {
    margin: 0px 48px 48px 18px;
    width: 60vw; }
  .careerFairScreen > img {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  @media only screen and (max-width: 680px) {
    .careerFairScreen > * {
      width: 100vw; } }

.E404S {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  text-align: center; }
  .E404S > div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

